import * as firebase from 'firebase';

function initialize() {
  const firebaseConfig = {
    apiKey: 'AIzaSyAWEgiqtmXcWEVf1QzcqLg-9uqEZDgX8ek',
    authDomain: 'chronos-courier-app.firebaseapp.com',
    databaseURL: 'https://chronos-courier-app.firebaseio.com',
    projectId: 'chronos-courier-app',
    storageBucket: 'chronos-courier-app.appspot.com',
    messagingSenderId: '493081507996',
    appId: '1:493081507996:web:eaa2b9db40e2b5283a9559',
    measurementId: 'G-6F3TZ3CR94',
  };
  firebase.initializeApp(firebaseConfig);
}

export const Firebase = {
  init: initialize,
  db: firebase.firestore,
};
