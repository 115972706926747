import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const AppRouter = ({ routes }) => {
  const defaultPage = () => {
    return <Redirect to="/customer-portal" />;
  };

  return (
    <Switch>
      {routes.map((route, index) =>
        route.component ? (
          <Route
            key={index}
            path={route.path}
            component={route.component}
            exact={route.exact === undefined ? false : route.exact}
          />
        ) : (
          route.render && (
            <Route
              key={index}
              path={route.path}
              render={route.render}
              exact={route.exact === undefined ? false : route.exact}
            />
          )
        ),
      )}
      {<Route component={defaultPage} />}
    </Switch>
  );
};

AppRouter.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default AppRouter;
