export default {
  staging: {
    route: 'https://api-chronos-staging.codedisruptors.com',
    mapKey: 'AIzaSyCi3qmmCGkA6XrnAlaCnuAMRWgn2_stuS8',
  },
  test: {
    route: 'https://centaurus.aeolus.ph',
    mapKey: 'AIzaSyCi3qmmCGkA6XrnAlaCnuAMRWgn2_stuS8',
  },
  production: {
    route: 'https://delphinus.aeolus.ph',
    mapKey: 'AIzaSyCVL2FiZkvtgbdjnwaKD9jVkUqxSFnigLw',
  },
};
