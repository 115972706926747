import React from 'react';

function ellipse(value) {
  return (
    <>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </div>
    </>
  );
}

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

function removeURLParamGoBack(location = '') {
  const prev = location.split('/');
  prev.pop();
  const loc = prev.join('/');

  return loc;
}

function extractBreadcrumb(pathname = '') {
  var paths = pathname.split('/');

  // remove the last element if there was a / at the end of the pathname
  paths =
    paths[paths.length - 1] === '' ? paths.slice(0, paths.length - 1) : paths;

  paths = paths[0] === '' ? paths.slice(1) : paths;
  // remove the first element if the second one is an empty string which means that we are in the root of the website
  paths = paths[1] === '' ? paths.slice(1) : paths;
  return paths;
}

function jsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
}

export const handleQueries = (
  query_string = [],
  filter_state = {},
  isUriEncode = false,
) => {
  const new_queries = query_string
    .map(string => {
      const query = string.trim();
      const clean_query =
        query[0] === '_'
          ? query.substring(1)
          : query === 'pageSize'
          ? 'limit'
          : query;
      const filter_query = (filter_state[clean_query] || '').trim();
      const encode_uri_query = filter_query
        ? encodeURIComponent(filter_query)
        : '';
      return `${query}=${isUriEncode ? encode_uri_query : filter_query}`;
    })
    .join('&');
  return new_queries;
};

export {
  extractBreadcrumb,
  parseQuery,
  ellipse,
  removeURLParamGoBack,
  jsonParse,
};
