import { createGlobalStyle, keyframes } from 'styled-components';

const wheel = keyframes`
	from {
		transform: rotateZ(0deg);
	}

	to {
		transform: rotateZ(360deg);
	}
`;

const GlobalStyle = createGlobalStyle`
	 @import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900);
	:root {
    --font-size: 14px;
    --font-family: 'Lato', 'sans-serif';
    --padding: 8px;
		--margin: 8px;
		--text-primary:#222425;
		--primary: #17A2B8;
		--secondary: #28A745;
		--background-color: #F1F5F8;
		--header-background: #1A373E;
		--accent: #F27124;
		--expand: #257B92;
		--sub: #12292B;
	}

	* {
    box-sizing: border-box;
  }

	.ant-form-item-has-error .ant-select .ant-select-selector,
	.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper,
	.ant-form-item-has-error .ant-input:hover,
	.ant-form-item-has-error .ant-input-affix-wrapper:hover,
	.ant-form-item-has-error .ant-input-number, .ant-form-item-has-error .ant-picker {
		border-color: red !important;
	}
	.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
		color: red !important;
		font-weight: 400 !important;
	}

	body,
  body #root,
  html {
    height: 100%;
  }


  body {
		color:var(--text-primary);
		font-weight: 400;
		font-family:  var(--font-family);
		padding:0;
		margin:0;
		background-color: var(--background-color);
	}

	button{
		font-weight:300;
		cursor:pointer;
		padding: 0.7em;
		border: 0;
		outline: none;
	}

	a {
		cursor:pointer;
		text-decoration: none;
		color:var(--text-primary);
	}

	li {
		list-style: none;
	}

	.disabled {
		opacity: 0.5;
		cursor: not-allowed !important;
	}

	.ant-btn {
		height: 30px;
    font-size: 0.8em;
	}

	.ant-layout {
		background: none;
	}

	/* SIDER STYLES */
	.ant-layout-sider-trigger {
		bottom: 40px;
	}

	.ant-layout-sider-has-trigger {
		padding-bottom: 0;
	}

	.menu-items {
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.ant-layout-sider-children {
		display: flex;
		flex-direction: column;
	}

	/* SELECT STYLES */
	.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled), .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #F2712470;
  }

	/* SECTION STYLES */
	.section {
		background-color: #F3F3F3;
		border: 2px solid #F3F3F3;
		border-radius: 3px;

		.section_body {
			padding: 20px;
		}
	}

	.section_bordered {
		background-color: #fff;
		border: 2px solid #C3C9D3;
	}

	.section_header {
		border-radius: 3px;
		background: linear-gradient(90deg, #187B92 0%, #004D5F 100%);
		border: 1px solid #C3C9D3;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		padding: 0 5px;
		color: #fff;
		justify-content: center;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;

		.header_name {
			text-transform: uppercase;
			font-size: 1em;
			flex: 1;
			padding: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	/* FORM GENERAL STYLES */
	.ant-form-item {
		padding-right: 20px;
		padding-left: 20px;
	}

	.ant-form-item-label {
		text-transform: uppercase;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;

		label {
			color: var(--text-primary);
		}
	}

	.ant-checkbox-inner{
		border: 1px solid black;
	}

	.ant-checkbox-checked .ant-checkbox-inner{
		background-color: #28A745;
		border-color:#28A745;
	}

	.ant-layout-sider-trigger {
		bottom: 50px;
		height: 30px;
	}

	.ant-layout-sider-trigger .anticon{
		vertical-align: super;
	}

	.ant-layout-sider-has-trigger {
		padding-bottom: 0;
	}

	.ant-layout-sider-children {
		display: flex;
		flex-direction: column;
	}

	.ant-checkbox-indeterminate .ant-checkbox-inner::after {
		background-color: #28A745;
	}

	.ant-checkbox-indeterminate .ant-checkbox-inner{
		background-color: #fff;
	}

	.ant-select{
		color: black;
	}

	.ant-modal-title{
		font-weight: bold;
		font-size: 1.75em;
	}

	/* Maintenance Page */
	.maintenance_wrapper, .noconnection_wrapper {
		background: var(--header-background);
		justify-content: center;
    align-items: center;
    height: 100%;
		color: #fff;

		img {
			height: 120px;
		}

		h1 {
			color: #fff;
			font-size: 3em;
		}

		span {
			color: var(--accent);
			font-weight: 600;
		}

		div {
			text-align: center;

			.btn_refresh {
				padding-right: 1em;
				padding-left: 1em;
			}
		}
	}

	.noconnection_wrapper {
		span {
			color: #fff;
		}
	}

	/* Loading Animation */

	.loading_wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		flex-direction: column;

	}

	@keyframes glow {
		0% {
			opacity: 0.5;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0.5;
		}
	}

	@keyframes bounce {
		0% {
			animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
			transform: scale(0.9099999999999999);
		}

		51% {
				animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
				transform: scale(1.02994);
		}

		100% {
				transform: scale(0.9099999999999999);
		}
	}

	.spinner .bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.spinner .bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}

	@-webkit-keyframes sk-bouncedelay {
		0%, 80%, 100% { -webkit-transform: scale(0) }
		40% { -webkit-transform: scale(1.0) }
	}

	@keyframes sk-bouncedelay {
		0%, 80%, 100% {
			-webkit-transform: scale(0);
			transform: scale(0);
		} 40% {
			-webkit-transform: scale(1.0);
			transform: scale(1.0);
		}
	}

	/* Other Styles */
	.text-center{
		text-align: center !important;
	}

	.bold{
		font-weight: 600;
	}

	.text-capitalize{
		text-transform: capitalize;
	}

	.menu-items {
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.ant-layout-sider-trigger {
		background: var(--expand);
	}

	.ant-menu-dark .ant-menu-inline.ant-menu-sub, .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
		background: var(--sub);
	}

	.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
		background: #257B9250;
	}


	.ant-select-dropdown {
		z-index: 9994;
	}

	.dashboard-filter {
		border: 1px dashed #C3C9D3;
		border-radius: 4px;
		padding: 4px !important;
	}

	.ellipsis {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.filter-name {
		color: var(--accent);
		font-weight: 500;
		line-height: 32px;
		font-size: 0.8em;
	}

	.filter-header {
		position: absolute;
    top: -20px;
    background: white;
    padding-left: 10px;
    padding-right: 10px;
		color: var(--accent);
		font-weight: 600;
		line-height: 32px;
		font-size: 0.9em;
	}

	//scroll design
	ul::-webkit-scrollbar-track{
		box-shadow:inset 0 0 6px rgba(0,0,0,.3);
		-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
		background-color:#f5f5f5
	}
	ul::-webkit-scrollbar {
		width: 6px;
		height: 7px;
	}
	ul::-webkit-scrollbar-thumb{
		background-color:#555;
		border:3px solid #555;
	}

	.modal-button {
    height: 38px;
		width: -moz-available;
		width: -webkit-fill-available;
    margin: 10px;
	}

	.cancel-button {
		background: #FF9800;
		border-radius: 3px;
		color: white;
	}

	.cancel-button:hover {
		background: red;
		color: white;
	}

	.submit-button {
		background: #17A2B8;
		border-radius: 3px;
	}

	#appointment_status_bar_text {
		display: none !important;
	}

	.recharts-cartesian-axis-line {
		stroke-width: 0.3px;
	}

	#truck_svg {
		opacity: 1;

		#right-wheel,
		#left-wheel {
			animation: ${wheel} 0.6s linear infinite;
			-moz-animation: ${wheel} 0.6s linear infinite;
			-webkit-animation: ${wheel} 0.6s linear infinite;
			transform-origin: center;
			transform-box: fill-box;
		}

		#truck-body, #truck-logo {
			transform-origin: bottom;
			animation-direction: alternate;
			animation-timing-function: ease-in-out;
		}

	}

	.ant-picker-dropdown {
		z-index: 9993;
	}

	.ant-modal-wrap {
		z-index: 9993;
	}


	.ant-select-selection--multiple {
		overflow-y: auto;
		max-height: 150px;
	}

	.rights-container {
    display: table-cell;
		vertical-align: middle;
		color: white;
	}

	.rights {
    margin: 0px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 10px;
    color: #fff;
    line-height: 1.7;
    /* width: 190px; */
	}

	.fa {
		position: relative;
		display: table-cell;
		width: 30px;
		height: 20px;
		text-align: center;
		vertical-align: middle;
		font-size:20px;
		}

	.ant-form-item-required.ant-form-item-no-colon {
		&::after {
			content: '*' !important;
			color: red;
			font-weight: 500;
			font-size: 1.2em;
		}
	}

	.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
		background: none !important;
	}

	@media (max-width: 767px) {
		.ant-col-sm-24.ant-form-item-label .ant-form-item-required.ant-form-item-no-colon::after {
			display: inline-block;
		}
	}

	.location_select__value-container{
		min-height: 32px;
	}

	.location_select__input {
		height: 32px;
	}

	.ant-time-picker-panel {
		z-index: 9993;
	}

	.react-select-custom {
			.location_select__control {
				border: 1px solid #d9d9d9;
			}

			.has-error {
				.location_select__control {
					border: 1px solid red;
				}
			}

			.location_select__menu-list {
				&::-webkit-scrollbar-track {
						box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
						-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
						background-color: #f5f5f5;
					}
					&::-webkit-scrollbar {
						width: 6px;
						height: 7px;
					}
					&::-webkit-scrollbar-thumb {
						background-color: #555;
						border: 3px solid #555;
					}
			}
	}

	.general-select {
		height: 32px;
		.location_select__control {
			height: 32px;
			min-height: 32px;

			.location_select__value-container, .location_select__indicators {
				min-height: inherit;
				height: inherit;
				top: -1px;
			}
		}
		.location_select__menu {
			.location_select__menu-list {
				&::-webkit-scrollbar-track {
						box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
						-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
						background-color: #f5f5f5;
					}
					&::-webkit-scrollbar {
						width: 6px;
						height: 7px;
					}
					&::-webkit-scrollbar-thumb {
						background-color: #555;
						border: 3px solid #555;
					}
				.location_select__option {

					padding: 5px 10px !important;
				}

			}
		}
	}

	.ant-form-item-control-input-content {
		.ant-input {
			font-size: 12px;
		}
		.ant-select {
			font-size: 12px;
		}
	}

	.ant-form-item-label {
		label {
			font-size: 12px;
		}
	}

	.ant-form-item {
		margin-bottom: 5px;
	}

	.inactive_location {
		color: #bbbbbb;
	}

	.ant-popover.ant-popover-placement-bottomRight {
		z-index: 998;
	}

	.ant-message-notice{
		text-align: right;
	}

	.ant-message{
		top: auto;
		bottom: 20px;
	}

	.ant-popover {
		z-index: 1050;
	}

	.pointer {
		cursor:pointer;
	}
	.ant-form-item-explain, .ant-form-item-extra {
		font-weight: 400;
	}

	.ant-form-item-explain, .ant-form-item-extra {
		min-height: 0;
	}

	.antd-transaction-log-form .ant-form-item-label {
		font-weight: 800;
	}

	.antd-transaction-log-form .ant-input {
		font-weight: normal;
	}

	.disabled-div {
		opacity: 0.5;
		cursor: not-allowed;
	}

	.update-status-btn {
		min-width: 19px;
    height: 19px;
		font-size: 0.58em;

		button {
			min-width: 19px;
			height: 19px;
		}
	}

	.note-finalize {
		color: #f42121;
    font-size: 11px;
    font-weight: 400;
	}

	.ant-select-selector {
		span {
			margin: 1px;
		}
	}

	.ant-select-selection-item {
		font-weight: 600;
	}

	.map-modal {
		border-radius: 3px;
		height: 95%;

		.ant-modal-content {
			height: 100%;
		}

		.ant-modal-close-x {
			width: auto;
			margin-right: 20px;
		}

		.ant-modal-body {
			max-height: 90%;
			min-height: 450px;
		}

		.ant-modal-header {
			background: linear-gradient(90deg, #187B92 0%, #004D5F 100%);
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;

			.ant-modal-title {
				color: #fff;
			}
		}
	}

	.component_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      flex-direction: column;

      .new_loader {
        position: fixed;
        z-index: 9994;
        top: 0;
        background-color: rgba(241, 245, 248, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        width: calc(100% - 200px);
        height: 100%;
        transition: width 0.2s;

        overflow: hidden;
      }

			.is_expanded {
				width: calc(100% - 80px);
        transition: width 0.2s;
			}
      /* transition: width 0.2s; */
    }

		.logo_title {
			border-radius: 150%;
			text-align: center;
			margin: auto;
			transition: ease;
			margin-bottom: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 700;
			color: #fff;
			font-size: 1.2em;
		}

		.ant-modal-confirm-title {
			span {
				font-weight: 600 !important;
			}
		}
  .noBorder {
	  border: none !important;
	}

	.margin-auto {
		margin: auto;
	}

	.required_field{
		border: 1px solid red !important
	}

	.ant-modal-mask {
		z-index: 9993;
	}

	.flexCenterCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ant-tooltip {
		z-index: 9995;
	}


	.ant-tabs-nav .ant-tabs-tab {
		color: black;
	}

	.ant-tabs-nav .ant-tabs-tab-active{
		color: #1890ff;
	}

	.text-red{
		color: red !important;
	}

	.geofence-pill{
    border-radius: 50%;
    width: 40px;
    text-align: center;
    margin: auto;
	}

	.not_allowed{
		cursor: not-allowed;
	}

	.ant-menu-submenu-popup{
		z-index: 9999
  }

	.table_customized th.ant-table-cell {
    color: #222425 !important;
		background: white !important;
		border-bottom-color: white !important;
		font: 12px Lato,sans-serif !important;
		font-weight: bold !important;
	}
	.table_customized td.ant-table-cell {
    font-size: 12px;
		color: #222425;
		border-bottom-color: white;
}

.card-loader {
	position: absolute;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	z-index: 1;
	background: rgba(255, 255, 255, 0.9);
	border-radius: 2px;
}

	div.card-container-customized{
		background-color: white;
	}

  [data-theme='compact']
    .card-container-customized
    > .ant-tabs-card
    > .ant-tabs-bar
    .ant-tabs-tab,
  .card-container-customized > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
	}

	.transaction-section-left{
		background-clip: content-box;
		border-right: none;
		border-top: none;
		border-bottom: none;
		padding-right: 8px;
	}

	.transaction-section-right{
		background-clip: content-box;
		border-left: none;
		border-top: none;
		border-bottom: none;
		padding-left: 8px;
  }

	@media (max-width: 992px) {
		.mobile-monitoring-main {
			padding: 0 !important;
			margin-top: 20px;
		}
	}

	.password-policy {
    font-size: 0.7rem;
    color: #F27124;
    padding: 10px 0;

		.title {
			text-transform: uppercase;
			font-weight:600;
			color: #555
		}
		.login-title {
			color: #fff;
		}
		.content {
			padding: 5px 10px;
		}
	}
	.login-policy {
			margin-left: 10px;
			text-align: left !important;
			color: #F17136 !important;
		}

	.portal_alert {
    text-align: left ;
    margin: 10px 0 20px 0 ;
		.ant-alert-message {
			font-size: 0.9em;
			color: #555 !important;
		}
  }

	.link {
		color: #257B92;
		cursor: pointer;
		:hover {
			color: #1890ff;
		}
	}

`;

export default GlobalStyle;
