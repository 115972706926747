import styled from 'styled-components';

const StyledStatic = styled.section`
  background-color: ${props =>
    props.background ? props.background : '#282c34'};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => (props.background ? '#282c34' : '#fff')};

  .logo {
    height: 94.24px;
    margin-top: -20vh;
  }

  .title {
    font-weight: 400;
    font-size: 30px;
    margin: 1vh 0;
  }

  .content {
    font-weight: 400;
    color: #8f8f8f;
  }
`;

const StyledFlex = styled.section`
  display: flex;
  width: auto;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'safe'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'safe')};
  flex-flow: row wrap;
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
`;

const StyledMaps = styled.section`
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  transition: all 0.2s;

  .auth {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 40px;
    padding: 0% 2%;

    color: #fff;
    background: linear-gradient(
      282.78deg,
      #1a373e 44.36%,
      rgba(55, 84, 90, 1) 126.38%
    );
    text-align: right;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .disable {
    cursor: not-allowed;
    color: #ededed;
  }

  .content {
    margin: 5%;
  }
`;

const StyledPortal = styled.section`
  width: 100%;
  height: 100%;
  color: #fff;
  overflow: auto;
  .bg-gradient {
    background: linear-gradient(
      282.78deg,
      #1a373e 44.36%,
      rgba(55, 84, 90, 1) 126.38%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 250px;
  }

  .auth {
    background: linear-gradient(
      282.78deg,
      #1a373e 44.36%,
      rgba(55, 84, 90, 1) 126.38%
    );
    text-align: right;
    padding: 1.5% 3%;

    span {
      cursor: pointer;
    }
  }
  .disable {
    cursor: not-allowed;
    color: #ededed;
  }

  .content-header {
    margin-top: -146px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 250px;
      margin-bottom: -10px;
    }

    .title {
      font-size: 3.2em;
      margin-bottom: 10px;
    }
    .sub-title {
      cursor: pointer;
      font-size: 1em;
      margin-top: 10px;
      color: #ff8a00;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .section {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      border: 0;
      height: 100%;
      width: 40%;
      position: relative;

      .disable {
        background-color: white;
        position: absolute;
        top: 0px;
        opacity: 0.6;
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: not-allowed;

        font-size: 0.9em;
        color: #000;
      }
    }
  }

  .content-body {
    color: #000;
    padding: 3% 10%;

    .detail-title {
      color: #64696d;
      font-size: 1.1em;
      font-weight: bold;
    }
    .linear {
      content: '';
      height: 2px;
      background: linear-gradient(90deg, #187b92 0%, #004d5f 100%);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .detail-body {
      margin-bottom: 30px;
    }
    .detail-sub,
    .detail-image {
      display: flex;
      justify-content: space-between;
      font-weight: 400;

      div {
        &:first-child {
          color: #ff8a00;
        }
        &:last-child {
          text-align: right;
          color: #5f5353;

          img {
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .content-header {
      .section {
        width: 80%;
      }
    }
  }

  @media (max-width: 600px) {
    .bg-gradient {
      background: linear-gradient(
        282.78deg,
        #1a373e 44.36%,
        rgba(55, 84, 90, 1) 126.38%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 100%;
      height: 180px;
    }

    .content-header {
      margin-top: -112px;

      img {
        width: 200px;
      }
      .title {
        font-size: 2.2em;
        margin-bottom: 10px;
      }
      .section {
        width: 90%;

        .disable {
          opacity: 0.8;
        }
      }
    }
    .content-body {
      margin: 20px 0px;
      padding: 3% 7%;

      .detail-sub {
        display: flex;
        flex-direction: column;
        div {
          &:last-child {
            text-align: left;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

const StyledBooking = styled.section`
  background: #fff;
  width: 100%;
  height: 100%;

  .auth {
    color: #fff;
    background: linear-gradient(
      282.78deg,
      #1a373e 44.36%,
      rgba(55, 84, 90, 1) 126.38%
    );
    text-align: right;
    padding: 1.5% 3%;

    span {
      cursor: pointer;
    }
  }

  .disable {
    cursor: not-allowed;
    color: #ededed;
  }

  .content {
    margin: 5%;
  }
`;

const StyledBookingRequest = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
  .auth {
    color: #fff;
    background: linear-gradient(
      282.78deg,
      #1a373e 44.36%,
      rgba(55, 84, 90, 1) 126.38%
    );
    text-align: right;
    padding: 1.5% 3%;

    span {
      cursor: pointer;
    }
  }

  .disable {
    cursor: not-allowed;
  }

  .content {
    margin: 5%;

    .detail-title {
      color: #ff8a00;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .linear {
      content: '';
      height: 2px;
      background: linear-gradient(90deg, #187b92 0%, #004d5f 100%);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .detail-body {
      margin-bottom: 30px;
      font-size: 0.9em;
      line-height: 2em;
    }
    .detail-sub,
    .detail-image {
      display: flex;
      justify-content: space-between;

      div {
        &:first-child {
          color: #ff8a00;
        }
        &:last-child {
          text-align: right;
          color: #5f5353;

          img {
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .detail-body {
      .detail-sub {
        font-size: 1.11em;
        line-height: 1.5em;

        display: flex;
        flex-direction: column;
        div {
          &:last-child {
            text-align: left;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

const StyledBKContent = styled.section`
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  .bk-main {
    color: white;
    position: absolute;
    right: 0;
    z-index: 1;
    margin-top: 40px;
    margin-right: ${props => (props.isExpand ? '0px' : '-300px')};

    transition: all 0.2s;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    .bk-content {
      display: flex;
      align-items: flex-start;

      .bk-burger {
        cursor: pointer;
        background: rgb(26, 55, 62, 0.8);
        padding: 10px 15px;
        font-size: 15px;
      }

      .bk-body {
        background: rgb(26, 55, 62, 0.8);
        height: calc(100vh - 40px);
        width: 300px;
        padding: 10px 20px;
        box-shadow: -3px 42px 2px 0px rgba(0, 0, 0, 0.4);

        .bk-fields {
          margin-bottom: 5px;
        }
        .bk-close {
          width: 100%;
          text-align: right;

          div {
            cursor: pointer;
          }
        }

        .bk-content-card {
          font-size: 12px;
          height: calc(100vh - 238px);
          overflow-y: scroll;
          margin-top: 5px;

          &::-webkit-scrollbar-track {
            background-color: #1a373e;
          }
          &::-webkit-scrollbar {
            width: 3px;
            height: 2px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 1);
          }

          .bk-card {
            background: #12292b;
            margin: 10px 0;
            padding: 10px 5px 10px 20px;
            border-radius: 10px;
            margin-right: 10px;
            box-shadow: 0px 4px 4px rgba(10, 26, 28, 0.64);

            .bk-card-title {
              margin-bottom: 10px;
            }

            .bk-card-label {
              display: flex;
              div:first-child {
                width: 130px;
              }
              div:last-child {
                width: 90px;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
`;

const StyledCardLoading = styled.section`
  margin: ${props => props.margin && props.margin};
  border-radius: ${props => props.circle && '100%'};
  background: linear-gradient(270deg, #12292b, #265559, #12292b);
  ${props =>
    props.gray &&
    'background: linear-gradient(to right, #e2e2e2 0%, #d1d1d1 45%, #dbdbdb 69%, #f5f5f5 100%);'}
  background-size: 400% 400%;

  width: ${props => (props.width ? props.width : '-webkit-fill-available')};
  height: ${props => (props.height ? props.height : '-webkit-fill-available')};

  -webkit-animation: loadingGradient 3s ease infinite;
  -moz-animation: loadingGradient 3s ease infinite;
  -o-animation: loadingGradient 3s ease infinite;
  animation: loadingGradient 3s ease infinite;

  @-webkit-keyframes loadingGradient {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
  @-moz-keyframes loadingGradient {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
  @-o-keyframes loadingGradient {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
  @keyframes loadingGradient {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
`;

const StyledTripContent = styled.section`
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  .hide-content {
    display: none;
  }
  .map-trip-content {
    overflow-y: scroll;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    margin-top: 40px;
    width: 200px;
    height: calc(100vh - 40px);
    color: white;
    /* background: rgb(26, 55, 62, 0.8); */

    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    .map-trip-card-selected {
      border: 2px solid white;
    }
    .map-trip-card {
      cursor: pointer;
      margin: 5px;
      padding: 5px;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.6);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      .map-trip-dot {
        content: '';
        position: absolute;
        z-index: 1;
        width: 15px;
        height: 15px;
        background: #0d9cda;
        border-radius: 100%;
        border: 2px solid #fff;
      }

      .map-trip-label {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 145px;
        margin-left: 25px;
        font-size: 0.8em;
        font-weight: bold;
      }
    }
  }
`;

export {
  StyledBKContent,
  StyledFlex,
  StyledStatic,
  StyledMaps,
  StyledPortal,
  StyledBooking,
  StyledBookingRequest,
  StyledCardLoading,
  StyledTripContent,
};
