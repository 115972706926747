import React from 'react';
import { StyledFlex } from './styledComponents/containers';

import Logo from 'assets/kerry-logistikus-logo.png';

function Maintenance() {
  return (
    <StyledFlex className="maintenance_wrapper">
      <img src={Logo} alt="Kronos logo" style={{ marginBottom: '2.5rem' }} />
      <h1>We&rsquo;ll be back soon!</h1>
      <div>
        {/* <p>This page is under maintenace</p> */}
        <p>
          &mdash; <span className="team_name">Kronos</span> Team
        </p>
      </div>
    </StyledFlex>
  );
}

export default Maintenance;
