import ApiService from 'utils/ApiService';
import { handleQueries } from './helpers';

const { get, put, notoken_get, post } = ApiService();

// CUSTOMER PORTAL

// GET MAPS
export const getMaps = () => {
  const route = '/customer-portal/visualization';

  return get(`${route}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// GET MAIN ORDER TRACKING
export const getMainTrack = ({ reference_no = '' }) => {
  const route = '/customer-portal/track-order';
  const params = `/${reference_no}`;

  return get(`${route}${params}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// GET DETAILS AND TRAIL
export const getOrderAndTrails = ({ id = '' }) => {
  const route = '/customer-portal/booking-requests';
  const params = `/${id}`;

  return get(`${route}${params}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// GET BOOKING CONSIGNMENT
export const getBookingConsignments = ({ id, page, pageSize, ...rest }) => {
  const query_string = [
    '_thu_id',
    '_planned_pickup_thu_qty',
    '_actual_pickup_thu_qty',
    '_actual_delivery_thu_qty',
    'sort_by',
    'sort_desc',
  ];

  const filter = handleQueries(
    query_string,
    {
      ...rest,
    },
    true,
  );
  const route = '/customer-portal/booking-requests';
  const limit_page = `page=${page || 1}&limit=${pageSize || 10}&`;

  return get(`${route}/${id}/consignments?${limit_page}${filter}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// GET BOOKING REQUEST DASHBOARD
export const getBookingRequestDashboard = queries => {
  const query_string = [
    'dashboard_rdd_from',
    'dashboard_rdd_to',
    '_booking_request_no',
    '_dispatch_document',
    '_customer_ref_no',
    '_senders_ref_no',
    '_receivers_ref_no',
    'rdd_from',
    'rdd_to',
    'status',
    'sort_by',
    'sort_desc',
  ];

  const filter = handleQueries(query_string, queries, true);
  const route = '/customer-portal/booking-requests/dashboard';

  return get(`${route}?${filter}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// GET BOOKING REQUEST DASHBOARD
export const getBookingRequestTotal = queries => {
  const query_string = [
    'dashboard_rdd_from',
    'dashboard_rdd_to',
    '_booking_request_no',
    '_dispatch_document',
    '_customer_ref_no',
    '_senders_ref_no',
    '_receivers_ref_no',
    'rdd_from',
    'rdd_to',
    'status',
    'sort_by',
    'sort_desc',
  ];

  const filter = handleQueries(query_string, queries, true);
  const route = '/customer-portal/booking-requests/total';

  return get(`${route}?${filter}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// GET BOOKING REQUEST
export const getBookingRequest = queries => {
  const query_string = [
    'dashboard_rdd_from',
    'dashboard_rdd_to',
    '_booking_request_no',
    '_dispatch_document',
    '_customer_ref_no',
    '_senders_ref_no',
    '_receivers_ref_no',
    'rdd_from',
    'rdd_to',
    'status',
    'sort_by',
    'sort_desc',
  ];

  const { page, pageSize } = queries;

  const filter = handleQueries(query_string, queries, true);
  const route = '/customer-portal/booking-requests';
  const limit_page = `page=${page || 1}&limit=${pageSize || 10}&`;

  return get(`${route}?${limit_page}${filter}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// END CUSTOMER PORTAL

// RFC 12 - RETRIVE PASSWORD POLICY
export const getPasswordPolicy = (type = 'Customer Portal User Pool') => {
  const route = `/password-policy?name=${type}`;

  return notoken_get(`${route}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// FIRST CHANGE PASSWORD
export const firstChangePassword = (body, session) => {
  const route = '/customer-portal/verify';
  return put(`${route}`, body, session)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// FIRST CHANGE PASSWORD
export const changePassword = body => {
  const route = '/customer-portal/change-password';
  return put(`${route}`, body)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// FORGOT PASSWORD
export const forgotPassword = body => {
  const route = '/customer-portal/forgot-password';
  return post(`${route}`, body)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

// RESET PASSWORD
export const resetPassword = body => {
  const route = '/customer-portal/reset-password';
  return post(`${route}`, body)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};
