import { lazy } from 'react';

const public_routes = [
  {
    name: 'Customer Potal',
    path: '/customer-portal',
    exact: true,
    component: lazy(() => import('containers/customerPortal/PortalLanding')),
  },
  {
    name: 'Customer Potal',
    path: '/customer-portal/auth',
    exact: true,
    component: lazy(() => import('containers/customerPortal/PortalLanding')),
  },
  {
    name: 'Customer Potal',
    path: '/customer-portal/initial-login',
    exact: true,
    component: lazy(() => import('containers/customerPortal/PortalLanding')),
  },
  {
    name: 'Customer Potal',
    path: '/customer-portal/reset',
    exact: true,
    component: lazy(() => import('containers/customerPortal/PortalLanding')),
  },
  {
    name: 'Customer Potal',
    path: '/customer-portal/booking-request',
    exact: true,
    component: lazy(() => import('containers/customerPortal/BookingRequest')),
  },
  {
    name: 'Customer Potal',
    path: '/customer-portal/booking-request/:id',
    exact: true,
    component: lazy(() =>
      import('containers/customerPortal/ViewBookingRequest'),
    ),
  },
  {
    name: 'Customer Potal',
    path: '/customer-portal/map',
    exact: true,
    component: lazy(() => import('containers/maps/Maps')),
  },
  {
    name: 'Success',
    path: '/success',
    exact: true,
    component: lazy(() => import('containers/staticPage/Static')),
  },
  {
    name: 'Success',
    path: '/reject',
    exact: true,
    component: lazy(() => import('containers/staticPage/Static')),
  },
  {
    name: 'Success',
    path: '/404',
    exact: true,
    component: lazy(() => import('containers/staticPage/Static')),
  },
];

export default public_routes;
