import React, { createContext, useContext, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import ApiService from 'utils/ApiService';
import { FilterContext } from 'context/filterContext';
import { parseQuery } from 'utils/helpers';
import { popToast } from 'utils/useToast';
import * as API from 'utils/API';

const PortalContext = createContext();
const Provider = PortalContext.Provider;

const { post, get } = ApiService();

const Auth = props => {
  const local = localStorage.p_auth && JSON.parse(localStorage.p_auth);
  const auth = local && local.isLogin;
  const maps_setting = local && local.maps_setting;

  const { setContextValue } = useContext(FilterContext);
  const [isLogin, setIsLogin] = useState(auth);
  const [showMap, setShowMap] = useState(maps_setting);
  const [isModal, setIsModal] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [isTrack, setIsTrack] = useState(false);
  const [isCompLoading, setIsCompLoading] = useState(true);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isChangePass, setIsChangePass] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState({});
  const [dataLogin, setDataLogin] = useState({});
  const [isForgotPass, setIsForgotPass] = useState(false);
  const [isResetPass, setIsResetPass] = useState(false);
  const [changePassLogin, setChangePassLogin] = useState(false);

  let { pathname, search } = useLocation();
  let { push } = useHistory();

  const value = {
    signIn: signIn,
    signOut: signOut,
    setIsLogin: setIsLogin,
    isLogin: isLogin,
    verify: verify,
    isModal: isModal,
    setIsModal: setIsModal,
    loginLoading: loginLoading,
    isTrack: isTrack,
    setIsTrack: setIsTrack,
    resetFilter: resetFilter,
    isPublic: isPublic,
    isCompLoading: isCompLoading,
    showMap: showMap,
    setShowMap: setShowMap,
    isFirstLogin: isFirstLogin,
    setIsFirstLogin: setIsFirstLogin,
    passwordPolicy: passwordPolicy,
    isChangePass: isChangePass,
    setIsChangePass: setIsChangePass,
    dataLogin: dataLogin,
    firstLogin: firstLogin,
    retrivePasswordPolicy: retrivePasswordPolicy,
    changePassword: changePassword,
    isForgotPass: isForgotPass,
    setIsForgotPass: setIsForgotPass,
    forgotPass: forgotPass,
    isResetPass: isResetPass,
    setIsResetPass: setIsResetPass,
    resetPass: resetPass,
    changePassLogin: changePassLogin,
    setChangePassLogin: setChangePassLogin,
  };
  function signIn(values, form) {
    setLoginLoading(true);
    post('/customer-portal/login', values)
      .then(res => {
        const data = res?.data;
        const maps_setting = data?.maps_setting;
        if (data?.temp_pass) {
          const local = JSON.stringify({ ...data });
          localStorage.setItem('p_auth', local);
          setDataLogin({ ...data, email: values?.email, maps_setting });
          retrivePasswordPolicy();
          setIsFirstLogin(true);
          setLoginLoading(false);
        } else if (data?.change_password) {
          const local = JSON.stringify({ ...data, loginChangePass: true });
          localStorage.setItem('p_auth', local);
          setDataLogin({ ...data, email: values?.email, maps_setting });
          retrivePasswordPolicy();
          setChangePassLogin(true);
          setLoginLoading(false);
        } else {
          const local = JSON.stringify({ ...data, isLogin: true });
          localStorage.setItem('p_auth', local);
          setShowMap(maps_setting);
          setLoginLoading(false);
          setIsLogin(true);
          setIsModal(false);
          setIsTrack(false);
          setIsFirstLogin(false);
          setIsChangePass(false);

          popToast({
            message: res.message,
            type: 'success',
          });
        }
        form.resetFields();
      })
      .catch(err => {
        const res = { ...err, ...err[0] };
        const {
          data: { error },
        } = res;
        setLoginLoading(false);
        popToast({
          message: error ? error.message : 'Something went wrong',
          type: 'error',
        });
      });
  }

  function verify() {
    localStorage.setItem('c_portal', true);
    publicValidation();
    if (
      pathname !== '/customer-portal' &&
      pathname !== '/customer-portal/reset' &&
      pathname !== '/customer-portal/initial-login' &&
      pathname !== '/customer-portal/auth'
    ) {
      if (!isLogin) {
        popToast({
          message: 'Access Denied.',
          type: 'error',
        });
        push('/customer-portal');
      }
    } else {
      const query = parseQuery(search);
      if (
        (query?.email && query?.password) ||
        (query?.email && query?.session)
      ) {
        retrivePasswordPolicy();
        setDataLogin({ ...dataLogin, ...query });
        setIsFirstLogin(true);
      } else if (query?.verification_code && query?.email) {
        retrivePasswordPolicy();
        setDataLogin({
          ...dataLogin,
          ...query,
        });
        setIsResetPass(true);
      } else {
        push('/customer-portal');
      }
      resetFilter();
    }
  }

  function signOut() {
    setLoginLoading(true);
    post('/customer-portal/logout')
      .then(() => {
        setLoginLoading(false);
        setIsLogin(false);
        setIsModal(false);
        setIsTrack(false);
        localStorage.clear();
        sessionStorage.clear();
        publicValidation();
        push('/customer-portal');
        document.location.reload();
      })
      .catch(() => {
        // const res = { ...err, ...err[0] };
        // const {
        //   data: { error },
        // } = res;

        setLoginLoading(false);
        setIsLogin(false);
        setIsTrack(false);
        publicValidation();
        // popToast({
        //   message: error ? error.message : 'Something went wrong',
        //   type: 'error',
        // });
        localStorage.clear();
        sessionStorage.clear();
        push('/customer-portal');
      });
  }

  function publicValidation() {
    setIsCompLoading(true);
    get('/settings/customer-portal')
      .then(res => {
        const value = res.value === 'true';
        setIsPublic(value);
        setIsCompLoading(false);
      })
      .catch(() => setIsCompLoading(false));
  }

  function resetFilter() {
    setContextValue({}, 'reset');
  }

  async function retrivePasswordPolicy() {
    try {
      const res = await API.getPasswordPolicy();
      setPasswordPolicy(res?.data[0]);
      setLoginLoading(false);
    } catch (err) {
      const res = { ...err, ...err[0] };
      const {
        data: { error },
      } = res;
      popToast({
        message: error ? error.message : 'Something went wrong',
        type: 'error',
      });
      setLoginLoading(false);
      setIsTrack(false);
      setIsModal(false);
      setIsFirstLogin(false);
    }
  }

  async function firstLogin(values, form) {
    setLoginLoading(true);
    try {
      const password = values?.new_password;
      const body = {
        ...values,
        email: dataLogin?.email,
        password,
      };
      const res = await API.firstChangePassword(body, dataLogin?.session);

      const data = res?.data?.authentication_result;
      const local = JSON.stringify({ ...data, isLogin: true });
      localStorage.setItem('p_auth', local);
      setShowMap(dataLogin?.maps_setting);
      setIsTrack(false);
      setLoginLoading(false);
      setIsModal(false);
      setIsFirstLogin(false);
      setIsChangePass(false);
      setIsLogin(true);

      popToast({
        message: res?.message,
        type: 'success',
      });
      form.resetFields();
    } catch (err) {
      const res = { ...err, ...err[0] };
      const {
        data: { error },
      } = res;
      setLoginLoading(false);
      setIsModal(true);
      popToast({
        message: error ? error.message : 'Something went wrong',
        type: 'error',
      });
    }
  }

  async function changePassword(values, form) {
    setLoginLoading(true);
    try {
      const password = values?.new_password;
      const body = {
        ...values,
        email: dataLogin?.email,
        password,
      };
      const res = await API.changePassword(body);
      setIsModal(false);
      setLoginLoading(false);
      setChangePassLogin(false);
      popToast({
        message: res?.message,
        type: 'success',
      });
      form.resetFields();
    } catch (err) {
      const res = { ...err, ...err[0] };
      const {
        data: { error },
      } = res;
      setLoginLoading(false);
      setIsModal(true);
      popToast({
        message: error ? error.message : 'Something went wrong',
        type: 'error',
      });
    }
  }

  async function forgotPass(values, form) {
    setLoginLoading(true);
    try {
      const res = await API.forgotPassword(values);
      setIsModal(false);
      setIsForgotPass(false);
      setLoginLoading(false);
      popToast({
        message: res?.message,
        type: 'success',
      });
      form.resetFields();
    } catch (err) {
      const res = { ...err, ...err[0] };
      const {
        data: { error },
      } = res;
      setLoginLoading(false);
      setIsModal(true);
      popToast({
        message: error ? error.message : 'Something went wrong',
        type: 'error',
      });
    }
  }

  async function resetPass(values, form) {
    setLoginLoading(true);
    try {
      const body = {
        ...values,
        email: dataLogin?.email,
        verification_code: dataLogin?.verification_code,
      };
      const res = await API.resetPassword(body);
      setIsModal(false);
      setIsResetPass(false);
      setLoginLoading(false);
      popToast({
        message: res?.message,
        type: 'success',
      });
      form.resetFields();
    } catch (err) {
      const res = { ...err, ...err[0] };
      const {
        data: { error },
      } = res;
      setLoginLoading(false);
      setIsModal(true);
      popToast({
        message: error ? error.message : 'Something went wrong',
        type: 'error',
      });
    }
  }
  return <Provider value={value}>{props.children}</Provider>;
};

const PortalProvider = withRouter(Auth);
export { PortalContext, PortalProvider };
